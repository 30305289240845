import React, { useEffect, useState } from "react"
import styled from "styled-components"

import useContentful, { MaterialCount } from "../../utils/hooks/useContentful"
import { Device } from "../../types/contentfulTypes"
import MockupCard from "../cards/MockupCard"
import PillButton from "../buttons/PillButton"
import LoaderAnimation from "../animations/LoaderAnimation"

export interface DevicesData {
  device: Device
  materialCounts: MaterialCount
  mockupsCount: number
}

const MockupsGrid = () => {
  const { getDevices, status } = useContentful()
  const [devicesData, setDevicesData] = useState<DevicesData[] | undefined>(undefined)
  const [length, setLength] = useState(10)

  useEffect(() => {
    getDevices().then(response => setDevicesData(response))

    return () => setDevicesData(undefined)
  }, [])

  return (
    <Wrapper>
      {status.state === "pending" ? (
        <LoaderAnimation />
      ) : (
        <Container>
          {devicesData?.slice(0, length).map((deviceData, index) => (
            <CardsGrid key={index}>
              <MockupCard deviceData={deviceData} />
            </CardsGrid>
          ))}
        </Container>
      )}
      {length < (devicesData?.length ?? 0) && (
        <PillButton title="Show more" onClick={() => setLength(length + 10)} />
      )}
    </Wrapper>
  )
}

export default MockupsGrid

const Wrapper = styled.div`
  max-width: 1234px;
  padding: 100px 20px 0;
  display: grid;
  gap: 40px;
  margin: 0 auto;
`

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  justify-content: center;
  justify-items: center;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
`

const CardsGrid = styled.div`
  max-width: 475px;
  width: 100%;
  height: 400px;

  @media (max-width: 420px) {
    max-width: 280px;
  }
`
