import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../../components/layout/Layout"
import { MetadataReadonly } from "../../types/contentfulTypes"
import TextSection from "../../components/sections/TextSection"
import * as TextStyles from "../../components/styles/TextStyles"
import MockupsGrid from "../../components/sections/MockupsGrid"
import CollectionCard from "../../components/cards/CollectionCard"
import DeviceMockupSection from "../../components/animations/DeviceMockupSection"
import { mockupsCount } from "../../data/MockupsData"
import useDownload from "../../utils/hooks/useDownload"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const Mockups = (props: Props) => {
  const { data } = props
  const { download } = useDownload()

  return (
    <Layout {...data}>
      <Wrapper>
        <Container>
          <DeviceMockupSection />
          <TextSection
            title="iOS, Android and Desktop mockups"
            description="We've created a free Sketch Plugin that allows you to place your screen into perspective mockups. Download 50 free mockups and try the plugin."
            buttonTitle="Download Sample"
            buttonIcon="/images/icons/downloads-pink.svg"
            onClick={download}
          />
        </Container>
        <TextContainer>
          <Title>{mockupsCount.total} unique vector devices</Title>
          <Description>
            Our mockups are fully layered and in vector, so there's no compromise in quality. You
            can resize the mockups infinitely.
          </Description>
        </TextContainer>
        <MockupsGrid />
        <CollectionContainer>
          <CollectionCard
            title="iOS"
            link="/collections/ios"
            image="../images/illustrations/collection-ios.png"
          />
          <CollectionCard
            title="Android"
            link="/collections/android"
            image="../images/illustrations/collection-android.png"
          />
          <CollectionCard
            title="Desktop"
            link="/collections/desktop"
            image="../images/illustrations/collection-desktop.png"
          />
        </CollectionContainer>
      </Wrapper>
    </Layout>
  )
}

export default Mockups

export const query = graphql`
  query MockupsQuery {
    contentfulMetadata(slug: { eq: "mockups" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 200px 0px;
`

const Container = styled.div`
  position: relative;
  display: grid;
  max-width: 1234px;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 587px 372px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1050px) {
    grid-template-columns: auto;
    gap: 100px;
    justify-content: center;
    justify-items: center;
  }

  .text-wrapper {
    @media (max-width: 1050px) {
      margin: 0 auto;
    }
  }
`

const TextContainer = styled.div`
  max-width: 390px;
  margin: 0 auto;
  padding: 150px 20px 0;
  text-align: center;

  .purchase {
    margin: 46px auto 0;
  }
`

const Title = styled(TextStyles.H3)``

const Description = styled(TextStyles.MediumText)`
  padding-top: 30px;
`

const CollectionContainer = styled.div`
  display: grid;
  max-width: 1234px;
  width: 100%;
  height: fit-content;
  grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  gap: 30px;
  justify-content: center;
  justify-items: center;
  padding: 180px 20px 0px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
`
