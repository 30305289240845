import { navigate } from "gatsby-link"
import React from "react"
import styled from "styled-components"

import { DevicesData } from "../sections/MockupsGrid"
import * as TextStyles from "../styles/TextStyles"

interface Props {
  deviceData: DevicesData
}

interface StyledProps {
  isLongTitle: boolean
}

const MockupCard = ({ deviceData }: Props) => {
  const { device, mockupsCount, materialCounts } = deviceData
  const illustration = device.mockups && device.mockups[0]?.images[0]?.file?.url

  let materialsArray: string[] = []

  Object.entries(materialCounts).forEach(([key, value]) => {
    if (key === "Real") {
      materialsArray.push(`${value} Colors`)
    } else {
      materialsArray.push(`${value} ${key}`)
    }
  })

  const description = `${mockupsCount} Mockups`
  const detailedDescription = materialsArray.join(", ")

  return (
    <Wrapper onClick={() => navigate(device.slug)}>
      <Title>{device.name}</Title>
      {illustration && (
        <Illustration src={illustration} alt="mockup" isLongTitle={device.name.length > 25} />
      )}
      <FeaturesContainer>
        <Feature>{description}</Feature>
        <DetailedFeature>{detailedDescription}</DetailedFeature>
      </FeaturesContainer>
    </Wrapper>
  )
}

export default MockupCard

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: grid;
  gap: 20px;
  padding: 40px;
  cursor: pointer;
  text-align: left;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.05);
  }
`

const Illustration = styled.img<StyledProps>`
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
  margin: 0;
  border-radius: 20px;
  padding: ${props => (props.isLongTitle ? "40px" : "20px")};

  @media (max-width: 420px) {
    max-width: 200px;
  }
`

const Title = styled(TextStyles.H5)`
  position: absolute;
  color: #000000;
  top: 20px;
  left: 20px;
  padding-right: 20px;

  @media (max-width: 520px) {
    font-size: 24px;
    top: 20px;
    left: 20px;
  }
`

const FeaturesContainer = styled.div`
  position: absolute;
  display: grid;
  max-width: 280px;
  width: fit-content;
  bottom: 20px;
  left: 20px;
  gap: 4px;

  @media (max-width: 520px) {
    max-width: 300px;
    bottom: 20px;
    left: 20px;
  }

  @media (max-width: 420px) {
    max-width: 250px;
  }
`

const Feature = styled(TextStyles.Caption)`
  color: #000000;

  span {
    font-weight: 400;
    color: #6c6a8e;
  }
`

const DetailedFeature = styled(TextStyles.Caption)`
  font-weight: 400;
  color: #6c6a8e;
`
