import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  title?: string
  icon?: string
  link?: string
  onClick?: () => void
}

const GhostButton = (props: Props) => {
  const { title, icon, link, onClick } = props

  return link ? (
    <MyLink to={link}>
      <Wrapper className="ghost">
        {icon && <Icon src={icon} alt="icon" />}
        <Title>{title || "Download Sample"}</Title>
      </Wrapper>
    </MyLink>
  ) : (
    <Wrapper className="ghost" onClick={onClick}>
      {icon && <Icon src={icon} alt="icon" />}
      <Title>{title || "Download Sample"}</Title>
    </Wrapper>
  )
}

export default GhostButton

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 20px;
  border: 1px solid #1986cc;
  box-sizing: border-box;
  border-radius: 0px 20px;
  cursor: pointer;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: translateY(-2px);
    background: #00cffd;

    p {
      color: #ffffff;
    }
  }
`

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin: 0;
`

const Title = styled(TextStyles.SmallText)`
  color: #1986cc;
`

const MyLink = styled(Link)`
  text-decoration: none;
`
