import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"
import GhostButton from "../buttons/GhostButton"

interface Props {
  title: string
  link: string
  image: string
  buttonText?: string
}

const CollectionCard = (props: Props) => {
  const { title, link, image, buttonText } = props

  return (
    <Wrapper className="card-wrapper">
      <Container>
        <Title className="title">{title}</Title>
        <GhostButton title={buttonText ?? "View Collection"} link={link} />
      </Container>
      <Illustration className="card" src={image} alt="illustration" />
    </Wrapper>
  )
}

export default CollectionCard

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  padding: 40px 40px 0px;
  background: #fafcff;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
  border-radius: 30px;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.02);
  }
`

const Container = styled.div`
  display: grid;
  max-width: 300px;
  width: 100%;
  height: fit-content;
  gap: 20px;
  text-align: left;

  .ghost {
    max-width: 160px;
  }
`

const Title = styled(TextStyles.H4)`
  max-width: 280px;
  width: 100%;
  color: #000000;
  padding-right: 20px;
`

const Illustration = styled.img`
  width: 100%;
  max-height: 500px;
  height: 100%;
  margin: auto auto 0;
  border-radius: 20px;
  padding: 10px;
  object-fit: contain;
`
